import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/sanitised-developer-database-images",
  "date": "25 January 2021",
  "title": "Sanitised Developer Database Images",
  "summary": "Keeping private data secure in development environments is becoming increasingly important. Sanitised database images can help.",
  "author": "Kim Pepper",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "mysql"
  }, {
    "name": "docker images"
  }, {
    "name": "dev tools"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`It is a common requirement to have an up to date copy of a database for local development. However,
this can present a number of challenges, including exposure of Personally Identifiable Information (PII).`}</p>
    <p>{`We've all read the headlines about data breaches due to database dumps being left on a developers machine or
publicly accessible server. This can be a PR disaster for your clients and lead to real world consquences for users.`}</p>
    <h3>{`The traditional approach`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`traditional`}</inlineCode>{` approach for securely sharing database images usually involves:`}</p>
    <ul>
      <li parentName="ul">{`Manual shelling into an environment`}</li>
      <li parentName="ul">{`Run a mysql dump`}</li>
      <li parentName="ul">{`Sync the files back to local`}</li>
      <li parentName="ul">{`Import the dump file into a local database`}</li>
    </ul>
    <p>{`Giving developers access to manually shell into an environment opens up a security risk,
not to mention the possibility of someone unintentionally dropping a live database.`}</p>
    <h3>{`The Skpr solution`}</h3>
    <p>{`Skpr mitigates this risk by providing nightly snapshots of sanitised databases as docker images.`}</p>
    <p>{`An automated nightly job looks like:`}</p>
    <ul>
      <li parentName="ul">{`Dump the database`}</li>
      <li parentName="ul">{`Sanitise the personally identifiable information`}</li>
      <li parentName="ul">{`Create a docker image of the database`}</li>
      <li parentName="ul">{`Push to a private repository`}</li>
    </ul>
    <p>{`By default, the sanitisation rules are configured for Drupal, but this can be configured to your use case.`}</p>
    <p>{`Using a simple command, developers can get the latest database image:`}</p>
    <pre><code parentName="pre" {...{}}>{`skpr mysql pull <env>
`}</code></pre>
    <p>{`In addition, a snapshot can be created on demand using:`}</p>
    <pre><code parentName="pre" {...{}}>{`skpr mysql create <env>
`}</code></pre>
    <h3>{`How do I use database images?`}</h3>
    <p>{`Database images can also be easily integrated into your own local development workflow. Below is an example `}<inlineCode parentName="p">{`docker-compose.yml`}</inlineCode>{` configuration.`}</p>
    <pre><code parentName="pre" {...{}}>{`mysql:
  image: xxxxxx.dkr.ecr.region.amazonaws.com/project/mysql:dev-default-latest
`}</code></pre>
    <p>{`Database images are also a perfect fit for `}<strong parentName="p">{`Continuous Integration`}</strong>{`. When using platforms like CircleCI you can
specify the database image you want to test on.`}</p>
    <p>{`By using Skpr, and running these scheduled tasks overnight you can rest assured that your sites and clients will never have PII leaks and that your databases are ready to go the next morning.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      